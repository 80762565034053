@import "src/styles/color";
@import "src/styles/mixin";

main {
  margin-left: 3rem;
  margin-right: 3rem;
  background-color: $background;

  #title {
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }

  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 1rem 0;
    resize: vertical;
  }

  button {
    font-size: 1rem;
    font-weight: bold;
    background-color: $theme;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $theme2;
    }
  }

  /* Add a background color and some padding around the form */
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
}
