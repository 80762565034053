@import "src/styles/color";
@import "src/styles/mixin";

#container {
  display: grid;
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 6rem auto;
  grid-template-areas:
    "menu longmenu"
    "menu content";
}

.header {
  grid-area: longmenu;
}

.sidebar {
  grid-area: menu;
}

.content {
  grid-area: content;
}
