@import "src/styles/color";

.post {
  margin-left: 3rem;
  background-color: $background;

  #title {
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }

  #username {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

.back {
  margin-left: 3rem;
  padding-top: 3rem;
}
