@import "src/styles/color";

nav {
  float: right;
  
  #header-list {
    display: flex;
    align-items: center;
    margin: 1.5rem 3rem;
    #search-bar {
      margin-right: 2rem;
    }
    img.rounded {
      object-fit: cover;
      border-radius: 50%;
      height: 2.7rem;
      width: 2.7rem;
    }
  }
}
