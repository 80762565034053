@import "src/styles/color";
@import "src/styles/mixin";

main {
  margin-left: 3rem;
  background-color: $background;

  #title {
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
    #new-post {
      color: $theme;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
