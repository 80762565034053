@import "src/styles/color";

.post-item-title {
  display: grid;
  grid-template-columns: 1.5fr 1fr 4fr;
  align-items: flex-start;
  column-gap: 1rem;
  padding-top: 2rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  margin-top: 0.5rem;
  
  .title {
    text-align: left;
  }
  .username {
    text-align: left;
  }
  .description {
    text-align: left;
  }
}

.post-item {
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 4fr;
  align-items: flex-start;
  column-gap: 1rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  margin-top: 0.5rem;

  .title {
    text-align: left;
  }
  .username {
    text-align: left;
  }
  .description {
    text-align: left;
  }
}
