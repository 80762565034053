@import "src/styles/color";
@import "/src/styles/mixin";

.sidebar {
  background-color: $theme-sidebar;
  min-height: 100vh;

  #sidebar-top {
    .logo {
      text-decoration: none;
      color: white;
      display: flex;
      align-items: center;
      font-size: 1.9rem;
      font-weight: bold;

      img {
        width: auto;
        height: 3.5rem;
        margin: 1.5rem 2.5rem;
      }
    }
  }

  .menu-list {
    text-decoration: none;
    padding: 4rem 0 0 0;

    .menu-item {
      @include rounded-button;
      white-space: pre-line;
      display: flex;
      height: 3.3rem;
      font-size: 1rem;
      text-decoration: none;
      color: $theme-text;
      column-gap: 1rem;

      &:hover {
        background-color: $theme;
      }
      &.active {
        @include button-hover;
      }
      div {
        align-self: center;
      }
      svg {
        padding-left: 2.5rem;
        align-self: center;
      }
    }
  }
}
